// import dynamic from "next/dynamic";

import SEO, { InviteDekhoSchema } from "@/components/utils/seo";
import { getTemplates } from "@/constants/template/template";
import { getPdfInvitation } from "@/services/designs/pdfInvitation";
import { getVideoInvitation } from "@/services/designs/videoInvitation";
import dynamic from "next/dynamic";
import FAQSection from "@/components/home/Dashboard/FAQSection";
import { homeFaqData } from "@/constants/home/homeFaqData";
import { Heading } from "@/components/home/Dashboard/common/heading";
import Head from "next/head";
const NewHeaderSV = dynamic(() => import("@/components/common/new-header-sv"));

const HeroSection = dynamic(() =>
  import("@/components/home/Dashboard/HeroSection")
);
const WebsiteDesigningSection = dynamic(
  () => import("@/components/home/WebsiteDesigningSection"),
  {
    ssr: false,
  }
);

const VideoInvites = dynamic(
  () => import("@/components/home/Dashboard/VideoInvites"),
  {
    ssr: true,
  }
);
const PDFInvites = dynamic(
  () => import("@/components/home/Dashboard/PDFInvites"),
  {
    ssr: true,
  }
);
const Websites = dynamic(() => import("@/components/home/Dashboard/Websites"), {
  ssr: true,
});
const Testimonials = dynamic(
  () => import("@/components/home/Dashboard/Testimonials"),
  {
    ssr: true,
  }
);
// const GallerySection = dynamic(
//   () => import("@/components/home/GallerySection"),
//   {
//     ssr: false,
//   }
// );

// const Budget = dynamic(() => import("@/components/home/Budget"), {
//   ssr: false,
// });
// const Feedbacks = dynamic(() => import("@/components/home/Feedbacks"), {
//   ssr: false,
// });

const Footer = dynamic(() => import("@/components/common/footer-sv"), {
  ssr: false,
});
// const WhatsappFloatingIcon = dynamic(
//   () => import("@/components/common/whatsapp-floating-icon-sv"),
//   {
//     ssr: false,
//   }
// );

// const LandingPageOfferPopup = dynamic(
//   () => import("@/components/home/LandingPageOfferPopup"),
//   {
//     ssr: false,
//   }
// );

// const FAQSection = dynamic(
//   () => import("@/components/home/common/FAQSection"),
//   {
//     ssr: false,
//   }
// );
// const InvitationPdf = dynamic(() => import("@/components/home/InvitationPdf"), {
//   ssr: false,
// });
// const Blog = dynamic(() => import("@/components/home/Blog"), {
//   ssr: false,
// });
// const SHOW_POPUP = false;

export default function Home({
  weddingInvites,
  engagementInvites,
  birthdayInvites,
  pdfInvites,
  websites,
}) {
  // const [open, setOpen] = useState(false);
  function websiteJsonLd() {
    return {
      __html: `
      {	
        "@context": "https://www.schema.org",
        "@type": "localbusiness",
        "name": "Invitedekho - India's leading brand for Digital Wedding Invitations",
        "telephone": "07895127272",
        "url": "https://www.invitedekho.com/",
        "logo": "https://www.invitedekho.com/assets/Logo.svg",
        "image": "https://www.invitedekho.com/assets/home/hero2.webp",
        "priceRange": "Affordable",
        "description": "Invitedekho offers a wide range of affordable digital wedding invitation videos, including templates, animations, and Indian wedding Invites.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.invitedekho.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string",
          "inLanguage": "en-US"
        },
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "India"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "21.1832788",
          "longitude": "82.7036649"
        },
        "hasMap": "https://maps.app.goo.gl/yBBB2hEv1W8vDyZDA", 
        "openingHours": "Mo, Tu, We, Th, Fr, Sa 09:00 AM - 08:00 PM Su 09:00 AM - 07:00",
           "contactPoint": {
           "@type": "PostalAddress",
           "contactType": "Customer Service",
           "telephone": "07895127272"
        },
         "aggregateRating" : {
            "@type" : "AggregateRating",
            "ratingValue" : "5",
            "bestRating" : "5",
            "worstRating" : "1",
            "ratingCount" : "54"
          },
          "review" : {
            "@type" : "Review",
            "author" : {
              "@type" : "Person",
              "name" : "Shruti Khurana"
            },
            "datePublished" : "2024-03-08",
            "reviewRating" : {
              "@type" : "Rating",
              "ratingValue" : "5",
              "bestRating" : "5",
              "worstRating" : "1"
            },
            "reviewBody" : "Very good services and very helpful people"
          }
         }
      `,
    };
  }

  // useEffect(() => {
  //   // console.log(window.location);
  //   let hostname = window.location.origin;

  //   window.location.href =
  //     hostname +
  //     "/designs/wedding-video-invitation/?labels=hindu_wedding&sortDirection=DESC&invites=wedding-video-invitation";
  // }, []);
  // useEffect(() => {
  //   SHOW_POPUP &&
  //     setTimeout(() => {
  //       setOpen(true);
  //     }, 10000);
  // }, []);

  return (
    <main className="relative">
      <SEO
        title="Wedding Invitation Video | Wedding Animation Video"
        description="Create the perfect Indian wedding website template at InviteDekho. Customize your online invitation, share your love story, & make your special day unique"
        keywords="Digital Invitations"
        url="https://www.invitedekho.com/"
        ogImage="/favicon.png"
      >
        {/* <script
          type="application/ld+json"
          dangerouslySetInnerHTML={websiteJsonLd()}
        /> */}
        {/* <InviteDekhoSchema /> */}
      </SEO>
      {/* <Head> */}
      {/* <InviteDekhoSchema /> */}
      {/* </Head> */}
      {/*/!* <TopBar /> *!/*/}

      <NewHeaderSV pageType="guest" />
      <div className="lg:mt-0 mt-14"></div>
      <HeroSection />
      <div className="mt-8">
        <div className="">
          <VideoInvites
            type={"wedding-video-invitation/"}
            headingClasses={"lg:pt-0 lg:mt-0"}
            invites={weddingInvites}
            title="Wedding Videos"
            // redTheme={true}
          />
          <VideoInvites
            type={"engagement-video-invitation/"}
            invites={engagementInvites}
            title="Engagement Videos"
            // redTheme={true}
          />
          <VideoInvites
            type={"birthday-video-invitation/"}
            invites={birthdayInvites}
            title="Birthday Videos"
            // redTheme={true}
            isLastSection={true}
          />
        </div>

        <PDFInvites invites={pdfInvites} />
        {process?.env?.NEXT_PUBLIC_ENV !== "prod" && (
          <Websites invites={websites} />
        )}

        <FAQSection
          data={homeFaqData}
          classes={"my-0 lg:pt-4 font-Jost pb-0"}
        />
        <Testimonials />
        <Footer />
      </div>
      {/* <Feedbacks /> */}

      {/* <WebsiteDesigningSection />
      <InvitationPdf />
      <GallerySection />
      <Budget />
      <section className="py-10">
        <FAQSection data={homeFaqData} />
      </section>
      <Blog />
      <WhatsappFloatingIcon />
      {SHOW_POPUP && <LandingPageOfferPopup open={open} setOpen={setOpen} />} */}
    </main>
  );
}

export async function getServerSideProps({ query, req }) {
  // const hostname = req.headers.host;
  // let domains = ["localhost:3000", "stage.invitedekho.com"];
  // if (!domains.includes(hostname)) {
  //   return {
  //     redirect: {
  //       destination: `https://${hostname}/designs/wedding-video-invitation/?labels=hindu_wedding&sortDirection=DESC&invites=wedding-video-invitation`,
  //       permanent: false,
  //     },
  //   };
  // }

  // const isProduction = environment === 'prod';
  let filter = {
    pageNo: 0,
    pageSize: 10,
    sortDirection: query?.sortDirection || "DESC",
    sortBy: ["createdAt"],
    filters: {
      labels: [],
      code: null,
      name: null,
      minCP: 0,
      minSP: 0,
      minMRP: 0,
      maxCP: 0,
      maxSP: 0,
      maxMRP: 0,
      featured: null,
      isPublished: true,
      eventType: "WEDDING",
    },
  };

  const [
    weddingInvites,
    engagementInvites,
    birthdayInvites,
    pdfInvites,
    websites,
  ] = await Promise.all([
    getVideoInvitation(filter),
    getVideoInvitation({
      ...filter,
      filters: { ...filter.filters, eventType: "ENGAGEMENT" },
    }),
    getVideoInvitation({
      ...filter,
      filters: { ...filter.filters, eventType: "BIRTHDAY" },
    }),
    getPdfInvitation(filter),
    getTemplates(),
  ]);

  return {
    props: {
      weddingInvites: weddingInvites.entities || [],
      engagementInvites: engagementInvites.entities || [],
      birthdayInvites: birthdayInvites.entities || [],
      pdfInvites: pdfInvites.entities || [],
      websites: websites.slice(0, 10),
    },
  };
}
